<template>
  <span class="tab">
    <b-badge
      variant="tab"
      :class="{ 'selected': selected, 'notification': notification }"
      @click="$emit('onClick')"
      pill>
        {{ name }}
      </b-badge>
  </span>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    name: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    notification: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
  @import "../../style/text";
  .tab {
    border-bottom: 1px solid @color_dark_purple;
    flex-grow: 1;
    padding-bottom: 1.5rem;
    margin-bottom: 2.5rem;
    .badge-tab {
        cursor: pointer;
        background-color: @color_dark_purple;
        font-size: @font_size_m;
        padding: 14px 20px;
        margin: 4px;
        position: relative;

        &.selected {
          background-color: @button-color-primary;
        }

        &.notification::after {
          content: "";
          position: absolute;
          border-radius: 50%;
          background-color: @color_radiant_pink;
          width: 12px;
          height: 12px;
          top: 1px;
          right: 0;
        }
    }
  }
</style>
