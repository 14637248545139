<template>
  <span class="ProductTypeBadge">
    <b-badge variant="avify" pill>{{ name }} <span @click="$emit(eventName, id)" class="dismiss-button" v-if="dismisable">&times;</span> </b-badge>
  </span>
</template>

<script>
export default {
  name: 'ProductTypeBadge',
  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: false
    },
    eventName: {
      type: String,
      required: false
    },
    dismisable: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="less">
  @import "productTypeBadge";
</style>
