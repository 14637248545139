<template>
  <div class="display-booking-position">
    <div class="d-flex w100 justify-content-between">
      <div class="top text-left">
        <h5>
          {{ this.position.getBusinessName }}
        </h5>
      </div>
      <div class="position-text text-right">
        <img class="icon" :src="require('@/assets/icons/position-pending.svg')">
        {{ position.getSimpleCreatedAt }}
      </div>
    </div>
    <div class="d-flex w100 justify-content-between position-contents">
      <div class="d-flex justify-content-between w-100 position-info flex-column flex-lg-row">
        <div>
          <div class="d-flex position-basic-info flex-row">
            <div class="text-left">
              <img
                class="business-avatar"
                :src="getAvatarUrl(position.getBusinessAvatar)"
                :alt="'avatar de' + position.getBusinessName"
              >
            </div>
            <div class="d-flex flex-column data">
              <span class="position-text">
              <img class="icon" :src="require('@/assets/icons/booking.svg')">
              Fechas: {{ position.getEventDate }}
            </span>
              <span class="position-text">
              <img class="icon" :src="require('@/assets/icons/watch.svg')">
              {{ position.getOnlyTime }}
            </span>
              <span class="position-text" v-if="position.getAnnotation">
              <img class="icon" :src="require('@/assets/icons/text-file.svg')">
              {{ position.getAnnotation }}
            </span>
              <div class="position-price d-flex">
                <div class="d-flex flex-column">
                  <span>Cantidad solicitada</span>
                  <span>{{ position.getQuantity }}</span>
                </div>
            </div>
          </div>
        </div>

        <div class="map-info mt-3 mt-lg-0">
          <div class="position-text text-left mb-2">
            <img class="icon" :src="require('@/assets/icons/icon-location-input.svg')">
            {{ position.getShortLocation }}
          </div>
          <Map
            :locationUrl="position.getGoogleMapsUrl"
          />
        </div>
      </div>
    </div>
    </div>
    <div class="extra">
      <div v-if="annotations">
        <h6>Anotaciones</h6>
        {{ annotations }}
      </div>
      <div>
        <h6>Marca</h6>
        <BrandBadge
          :name="brand.name"
          :id="brand.id"
        />
      </div>
      <div>
        <h6>Tipo de equipo</h6>
        <ProductTypeBadge
          :name="productType.name"
          :id="productType.id"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Map from '@/components/Map/Map'
import BrandBadge from '@/components/BrandBadge/BrandBadge'
import ProductTypeBadge from '@/components/ProductTypeBadge/ProductTypeBadge'

export default {
  name: 'DisplayHardwareBookingPosition',
  components: {
    ProductTypeBadge,
    BrandBadge,
    Map
  },
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  computed: {
    workoutPDF () {
      return process.env.VUE_APP_IMAGE_API_URL + '/workout/workout_client.pdf'
    },
    brand () {
      return this.position.brand
    },
    productType () {
      return this.position.productType
    },
    annotations () {
      return this.position.annotations
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_IMAGE_API_URL,
      popover: false
    }
  },
  created () {
    this.setPositionAsSaw()
  },
  watch: {
    position: function () {
      this.setPositionAsSaw()
    }
  },
  methods: {
    getAvatarUrl (avatar) {
      if (!avatar) {
        return '/img/default-user-avatar.svg'
      }

      return this.baseUrl + avatar.path
    },
    setPositionAsSaw () {
      const data = {
        positionId: this.$route.params.id,
        ownerSawIt: true
      }

      this.$store.dispatch('hardwarePosition/updateHardwareBookingContactedFromTechnician', data)
        .then(() => {
          this
            .$store
            .dispatch('hardwarePosition/getContactedHardwareTechnician')
        })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../style/colors.less';
@import '../../style/text.less';
@import "../../style/responsive";

.display-booking-position {
  background-color: @color_dark_purple;
  padding: 2rem;
  border-radius: 4px;
  margin-bottom: 2rem;
  .top {
    margin-bottom: 1.5rem;
    h5 {
      margin: 0;
      padding: 0;
      font-size: 1.5rem;
    }
  }
  .position-contents {
    .business-avatar {
      width: 80px;
    }
    .data {
      margin-left: 1.5rem;
      text-align: left;

      > span {
        margin-bottom: 1rem;
      }
      .position-title {
        color: @color_radiant_pink;
        font-size: 1rem;
        font-weight: 500;
      }
      .position-price {
        font-family: @font_secondary;
        font-weight: 500 !important;
        font-size: 0.65rem;
        color: #D5D5DC;
        > div {
          background-color: @color_dark_blue_light;
          border-radius: 5px;
          margin-right: 0.82rem;
          padding: 0.7rem 0.9rem;
          text-align: center;
          min-width: 84px;
          @media (max-width: @break_xs) {
            min-width: auto;
          }
        }
      }
    }

    @media (max-width: @break_xs) {
      flex-direction: column;
    }
  }
  .extra {
    margin-top: 4rem;
    text-align: left;
    > div {
      border-bottom: 1px solid #ffffff51;
      padding-bottom: 0.7rem;
      margin-bottom: 1rem;
      &:last-of-type {
        padding: 0;
        margin: 0;
        border-bottom-width: 0;
      }
    }
  }
  .position-text {
    font-size: 0.75rem;
  }
  img.icon {
    width: 14px;
  }
}
</style>
