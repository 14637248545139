<template>
  <div id="positions">
    <Header
      :user="loggedUser"
      :currentRouteName="$route.name"
    />
    <b-container fluid="sm" class="position-container">
      <b-row>
        <b-col>
          <div class="tab-container d-md-none">
            <b-container class="d-flex justify-content-between">
              <Tab
                name="Inbox"
                :selected="displayedCard == 'inbox'"
                @onClick="goTo('positions-inbox')"
                :notification="hasInboxUnreadNotifications"
              />
              <Tab
                name="Pendientes"
                :selected="displayedCard == 'pending'"
                @onClick="goTo('positions-pending')"
              />
              <Tab
                name="Aceptados"
                :selected="displayedCard == 'accepted'"
                @onClick="goTo('positions-accepted')"
                :notification="hasAcceptedUnreadNotifications"
              />
              <Tab
                name="Rechazados"
                :selected="displayedCard == 'rejected'"
                @onClick="goTo('positions-rejected')"
                :notification="hasRejectedUnreadNotifications"
              />
            </b-container>
          </div>
          <b-card class="side-card d-none d-md-block">
            <b-row class="flex-column">
              <b-col>
                <div
                  class="item inbox"
                  :class="{ 'selected': displayedCard == 'inbox', 'notification': hasInboxUnreadNotifications }"
                  @click="goTo('positions-inbox')"
                >
                  <img
                    :src="require('@/assets/icons/position-inbox.svg')"
                    v-if="displayedCard !== 'inbox'"
                  >
                  <img
                    :src="require('@/assets/icons/position-inbox-selected.svg')"
                    v-else
                  >
                  Inbox
                </div>
              </b-col>
              <b-col>
                <div
                  class="item pending"
                  :class="{ 'selected': displayedCard == 'pending' }"
                  @click="goTo('positions-pending')"
                >
                  <img
                    :src="require('@/assets/icons/position-pending.svg')"
                    v-if="displayedCard !== 'pending'"
                  >
                  <img
                    :src="require('@/assets/icons/position-pending-selected.svg')"
                    v-else
                  >
                  Pendientes
                </div>
              </b-col>
              <b-col>
                <div
                  class="item accepted"
                  :class="{ 'selected': displayedCard == 'accepted', 'notification': hasAcceptedUnreadNotifications }"
                  @click="goTo('positions-accepted')"
                >
                  <img
                    :src="require('@/assets/icons/position-accepted.svg')"
                    v-if="displayedCard !== 'accepted'"
                  >
                  <img
                    :src="require('@/assets/icons/position-accepted-selected.svg')"
                    v-else
                  >
                  Aceptados
                </div>
              </b-col>
              <b-col>
                <div
                  class="item rejected"
                  :class="{ 'selected': displayedCard == 'rejected', 'notification': hasRejectedUnreadNotifications }"
                  @click="goTo('positions-rejected')"
                >
                  <img
                    :src="require('@/assets/icons/position-rejected.svg')"
                    v-if="displayedCard !== 'rejected'"
                  >
                  <img
                    :src="require('@/assets/icons/position-rejected-selected.svg')"
                    v-else
                  >
                  Rechazados
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md=8>
          <slot></slot>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Tab from '@/components/Tab/Tab.vue'

export default {
  name: 'BookingPosition',
  components: { Header, Tab },
  props: {
    displayedCard: {
      type: String,
      default: 'inbox'
    }
  },
  data () {
    return {
      intervalHandler: null
    }
  },
  mounted: function () {
    this
      .$store
      .dispatch('getContactedTechnicianAsTechnician')

    this.intervalHandler = setInterval(() => {
      this
        .$store
        .dispatch('getContactedTechnicianAsTechnician')
    }, 5 * 60 * 1000)
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    },
    hasInboxUnreadNotifications () {
      return this.$store.getters.technicianBookingNotifications.some((element) => {
        return element.technicianAccepted === null &&
          element.businessAccepted === null
      })
    },
    hasAcceptedUnreadNotifications () {
      return this.$store.getters.technicianBookingNotifications.some((element) => {
        return element.businessAccepted === true
      })
    },
    hasRejectedUnreadNotifications () {
      return this.$store.getters.technicianBookingNotifications.some((element) => {
        return element.technicianAccepted === false ||
          element.businessAccepted === false
      })
    }
  },
  methods: {
    goTo (route) {
      if (this.$route.name === route) {
        return
      }
      this.$router.push({ name: route })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "../style/responsive";

  @import 'pendings.less';

  .tab-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
    margin-left: -15px;
    margin-right: -15px;
    &::-webkit-scrollbar {
      display: none;
    }
    > .container {
      min-width: 470px;
    }
  }

  .card-body {
    @media (max-width: @break_xs) {
      padding-left: 10px !important;
    }
  }
</style>
