<template>
  <iframe
    class="Map"
    :src="this.locationUrl"
    height="300px"
    width="100%"
    style="border:0;"
    allowfullscreen=""
    loading="lazy">
  </iframe>
</template>

<script>

export default {
  name: 'Map',
  props: {
    locationUrl: String
  }
}
</script>
